import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import localforage from "localforage";
import {
  Button,
  Box,
  Avatar,
  Collapse,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  Modal,
  Typography,
  InputLabel,
  OutlinedInput,
  ClickAwayListener,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { UpdateSalesPerson, CreateSalesPerson } from "../../../../store/checkout/checkout.action";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";

 
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    height: 40,
    "& span": {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
  },
  button: {
    width: "100%",
    backgroundColor: "transparent",
    color: "#657288",
    border: "1px solid #A6B4CB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  doneButton: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    color: "#657288",
  },
  box: {
    position: "relative",
  },
  active: {
    borderBottom: "none",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  scanCodeIcon: {
    color: theme.palette.primary.main,
  },
  collapse: {
    position: "absolute",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    border: "1px solid #A6B4CB",
    borderTop: "none",
    backgroundColor: theme.palette.white.main,
    width: "calc(100% - 2px)",
    zIndex: 2,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
      marginRight: 13,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  avatar: {
    height: 37,
    marginRight: 11,
    color: theme.palette.blue.main,
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
  },
  not: {
    backgroundColor: "none",
    color: theme.palette.blue.main,
  },
  collapseContent: {
    padding: "8px 11px",
    backgroundColor: theme.palette.secondary.main,
  },
  linkSection: {
    borderTop: "1px solid #f2f4f7",
  },
  noWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 14,
    justifyContent: "space-between",
    color: theme.palette.blue.main,
  },
  userIcon: {
    marginLeft: 4,
  },
  clientList: {},
  doneIcon: {
    fontSize: "16px",
    color: theme.palette.blue.main,
  },
  paper: {
    width: "500px",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    textAlign: "left",
    paddingLeft: 5,
  },
  formInput: {
    width: "100%",
    marginBottom: 15,
    height: 35,
    fontSize: 12,
  },
  modalButton: {
    marginLeft: 5,
    marginRight: 5,
  },
}));
export const SalesPerson = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openMiniForm, setOpenMiniForm] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "checkout"]);

  const salesPersonList = useSelector((state) => state.Checkout.salesPersonList);
  const store = useSelector((state) => state.Login.selectedData);
  const storePos = useSelector((state) => state.Login.pos);

  const [createSalesPersonForm, setCreateSalesPersonForm] = useState({
    sales_person_name: null,
    commission_rate: null,
  });

  // Load cached salesperson on mount and set initial selection
  useEffect(() => {
    const loadCachedSalesPerson = async () => {
      const cachedSalesPersonSelected = await localforage.getItem("reduxPersist:SalesPerson");
      if (cachedSalesPersonSelected) {
        
        const initialCheckedItems = salesPersonList
          .filter((item) => cachedSalesPersonSelected.includes(item.sales_person_name))
          .map((item) => item.sales_person_name);

        setCheckedItems(initialCheckedItems);
        dispatch(UpdateSalesPerson(initialCheckedItems));
      }
    };
    loadCachedSalesPerson();
  }, [salesPersonList, dispatch]);

  // Toggle item selection
  const handleCheck = (event) => {
    const { value } = event.target;
    const isCheckedNow = event.target.checked;

    let updatedList;
    if (isCheckedNow) {
      updatedList = [...checkedItems, value];
    } else {
      updatedList = checkedItems.filter((item) => item !== value);
    }

    setCheckedItems(updatedList);
    dispatch(UpdateSalesPerson(updatedList));
  };

  const isChecked = (item) => checkedItems.includes(item);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "commission_rate" && (value < 100 && value > 0)) {
      setCreateSalesPersonForm((prevForm) => ({ ...prevForm, [name]: value }));
    } else if (name !== "commission_rate") {
      setCreateSalesPersonForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const createSalesPerson = () => {
    if (createSalesPersonForm.sales_person_name && createSalesPersonForm.commission_rate) {
      const data = {
        doctype: "Sales Person",
        is_group: 0,
        enabled: 1,
        sales_person_name: createSalesPersonForm.sales_person_name,
        parent_sales_person: "Sales Team",
        commission_rate: createSalesPersonForm.commission_rate,
        warehouse: store?.store,
      };
      dispatch(CreateSalesPerson(data));
      setOpenMiniForm(false);
      setCreateSalesPersonForm({ sales_person_name: null, commission_rate: null });
    }
  };

  const miniForm = (
    <ClickAwayListener onClickAway={() => setOpenMiniForm(false)}>
      <Box className={classes.paper}>
        <Typography align={"center"} color={"primary"}>
          {t("common:addVendor")}
        </Typography>
        <Box mt={3} className={classes.formBlock}>
          <InputLabel className={classes.label}>{t("common:vendorName")}</InputLabel>
          <OutlinedInput
            autoComplete="off"
            className={classes.formInput}
            placeholder={t("common:vendorName")}
            value={createSalesPersonForm.sales_person_name}
            onChange={handleChange}
            name="sales_person_name"
          />
          <InputLabel className={classes.label}>{t("common:commission")}</InputLabel>
          <OutlinedInput
            autoComplete="off"
            type="number"
            className={classes.formInput}
            placeholder={t("common:commission")}
            value={createSalesPersonForm.commission_rate}
            onChange={handleChange}
            name="commission_rate"
          />
        </Box>
        <Box mt={3}>
          <Button
            disabled={!createSalesPersonForm.sales_person_name || !createSalesPersonForm.commission_rate}
            variant="contained"
            color="primary"
            className={classes.modalButton}
            onClick={createSalesPerson}
          >
            {t("common:ajouter")}
          </Button>
          <Button
            className={classes.modalButton}
            variant="contained"
            color="secondary"
            onClick={() => setOpenMiniForm(false)}
          >
            {t("common:annuler")}
          </Button>
        </Box>
      </Box>
    </ClickAwayListener>
  );

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box className={classes.box}>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            className={clsx({
              [classes.button]: true,
              [classes.active]: open,
            })}
            onClick={() => setOpen((prev) => !prev)}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Avatar className={classes.avatar} />
              {t("common:vendeurs")} ({checkedItems.length})
            </Box>
            <ExpandMore />
          </Button>
          <Collapse className={classes.collapse} in={open} timeout="auto" unmountOnExit>
            <List className={classes.clientList} component="div">
              {salesPersonList.map((row, index) => (
                <ListItem key={index} className={classes.nested}>
                  <FormControlLabel
                    value={row.sales_person_name}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={handleCheck}
                        checked={isChecked(row.sales_person_name)}
                        value={row.sales_person_name}
                      />
                    }
                    label={`${row.sales_person_name} (${checkedItems.filter(
                      (s) => s === row.sales_person_name
                    ).length})`}
                    labelPlacement="end"
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      </ClickAwayListener>
      <Modal open={openMiniForm} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        {miniForm}
      </Modal>
    </>
  );
};