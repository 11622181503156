import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  makeStyles,
  InputAdornment,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "../../style";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPOSList,
  GetPOSOpenings,
  GetStores,
  SetSelectedStoreAndCashier,
  SetPOSOpeningDate,
  SetSignature,
  signatureOpen,
  setUser,
  logout,
} from "../../../../store/login/login.action";
import * as localforage from "localforage";
import { useHistory } from "react-router-dom";
import { formatDate, timeout } from "../../../../helpers/utils";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  LoadSalesPerson,
  setDefaultClient,
  UpdateClient,
} from "../../../../store/checkout/checkout.action";
import { useTranslation } from "react-i18next";
import { UpdateThemes } from "../../../../store/theme/theme.action";
import { Divider, DialogActions, Dialog } from "@material-ui/core";
import SignaturePad from "react-signature-pad-wrapper";
import Link from "@material-ui/core/Link";
import { XReport } from "../../../session/components/xReport";
import { useReactToPrint } from "react-to-print";
import axiosInstance from "../../../../history/axiosInstance";
import { loadSalesPersonData } from "../../../initialization/api";
 
const useStyless = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "5%",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    padding: theme.spacing(3),
    backgroundColor: "#EEF2F6",
  },
  logo: {
    width: "30%",
    aspectRatio: "3/2",
    objectFit: "contain",
    mixBlendMode: "darken",
  },
}));
 
export const CashierForm = (props) => {
  const classes = useStyles();
  const classess = useStyless();
  const dispatch = useDispatch();
  const { t } = useTranslation(["login", "common"]);
  const [cashiers, setCashiers] = useState([]);
 
  const stores = useSelector((state) => state.Login.stores);
  const posList = useSelector((state) => state.Login.pos);
  const openedPos = useSelector((state) => state.Login.openedPos);
  const user = useSelector((state) => state.Login.user);
  const history = useHistory();
  const selectedData = useSelector((state) => state.Login.selectedData);
  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
  const cushFund = useSelector((state) => state.Login.cushFund);
  const open = useSelector((state) => state.Pos.open);
  const contactCompany = useSelector((state) => state.Login.selectedData);
  var themes = useSelector((state) => state.Theme.themes);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const signPad = useRef({});
  const invoices = useSelector((state) => state.Pos.invoices);
  const coffreOperations = useSelector((state) => state.Pos.coffreOperations);
  const componentRefX = useRef();
  const openSignature = useSelector((state) => state.Login.openSignature);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
 const [salesPersonList, setSalesPersonList] = useState([]);
 const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
 
  const handleLogout = async () => {
    await dispatch(logout());
    await timeout(2000);
    history.push("/login");
  };
  const handleLoadSalesPersonData = async () => {
    await loadSalesPersonData()
      .then((response) => {
        // Dispatch the data to the Redux store
        dispatch(LoadSalesPerson(response.data));
  
        // Log the salesperson list to the console
        setSalesPersonList(response.data);
         
      })
      .catch((err) => {
        console.log("Error loading sales person data:", err);
      });
  };
 useEffect(() => {
    handleLoadSalesPersonData();
  }, []);
  const handleSalesPersonChange = (event) => {
    setSelectedSalesPerson(event.target.value); // Update selected salesperson
   
   
  };
   
  const clear = () => {
    dispatch(signatureOpen(signPad.current.clear()));
  };
  const trim = () => {
    dispatch(signatureOpen(signPad.current.toDataURL("image/png")));
  };
 
  const [open1, setOpen1] = React.useState(false);
 
  const handleClickOpen = () => {
    setOpen1(true);
  };
 
  const handleClose = () => {
    setOpen1(false);
  };
 
  const handleChange =async (event) => {
    const cashiersData = posList.filter(
      (s) => s.warehouse === event.target.value
    );
    const contactData = stores.filter((s) => s.name === event.target.value);
   await  handleLoadSalesPersonData();
    setCashiers(cashiersData);
    dispatch(
      SetSelectedStoreAndCashier({
        store: event.target.value,
        contact: contactData,
        cashier: null,
      })
    );
  };
 
  const handleCashierChange = (event) => {
    dispatch(
      SetSelectedStoreAndCashier({
        store: selectedData.store,
        contact: selectedData.contact,
        cashier: event.target.value,
      })
    );
  };
 
  const handleValidateWarehouse = (event) => {
    handleDefaultCustomer();
    localforage.setItem("reduxPersist:SalesPerson", selectedSalesPerson);
    history.push("/user/cashier-opening-amount");
  };
 
  const handleDefaultCustomer = () => {
    if (Array.isArray(stores)) {
      const defaultCustomer = stores?.filter(
        (store) => store?.name === selectedData?.store
      )?.[0]?.default_customer;
      if (defaultCustomer) {
        let url = `${process.env.REACT_APP_API_URI}/api/details?doctype=Customer&name=${defaultCustomer}`;
        return axiosInstance
          .get(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              Authorization: JSON.parse(localStorage.getItem("user"))?.token,
            },
          })
          .then(({ data }) => {
            dispatch(setDefaultClient(data?.docs?.[0]));
            dispatch(UpdateClient(data?.docs?.[0]));
          });
      }
    }
  };
 
  useEffect(() => {
    if (globalDefaults) {
      dispatch(setUser(user));
      dispatch(GetStores(globalDefaults?.default_company, user));
      dispatch(GetPOSList());
      dispatch(GetPOSOpenings());
      localforage.getItem("reduxPersist:Login", function (err, value) {
        var item = JSON.parse(value);
        if (item.selectedData && item.selectedData.store) {
          const cashiersData = item.pos.filter(
            (s) => s.warehouse === item.selectedData.store
          );
          setCashiers(cashiersData);
        }
      });
    }
  }, [globalDefaults]);
 
  const ChangeThemeValid = () => {
    const lastThemeIndex = themes.findIndex((obj) => obj.active === 1);
    const store = stores.filter((f) => f.name == selectedData.store);
    const id_theme_warehouse =
      store.map((m) => m.theme_id) === ""
        ? 0
        : store.map((m) => m.theme_id).toString();
    const posProfile = posList.filter((f) => f.name == selectedData.cashier);
    const id_theme_pos =
      posProfile.map((m) => m.theme_id) === ""
        ? 0
        : posProfile.map((m) => m.theme_id).toString();
    let theme_pos = themes.find((s) => s.theme_id == id_theme_pos);
    let theme_warehouse = themes.find((s) => s.theme_id == id_theme_warehouse);
    if (id_theme_warehouse && id_theme_pos) {
      themes[lastThemeIndex].active = 0;
      theme_pos.active = 1;
      themes[id_theme_pos] = theme_pos;
      dispatch(UpdateThemes(themes));
    } else if (id_theme_warehouse) {
      themes[lastThemeIndex].active = 0;
      theme_warehouse.active = 1;
      themes[id_theme_warehouse] = theme_warehouse;
      dispatch(UpdateThemes(themes));
    }
    dispatch(signatureOpen(null));
    dispatch(SetSignature(null));
  };
 
  const valid = () => {
    dispatch(SetPOSOpeningDate(formatDate(Date()).toString()));
    if (!open || open?.pos_profile !== selectedData?.cashier) {
      ChangeThemeValid();
      handleValidateWarehouse();
    } else {
      handleClickOpen();
    }
  };
 
  const hasPOSInWarehouse = (warehouseName) => {
    return posList.some((pos) => pos.warehouse === warehouseName);
  };
 
  const handlePrint = useReactToPrint({
    content: () => componentRefX.current,
    // onAfterPrint: () => dispatch(SetSignature(null))
  });
 
  return (
    <>
      <div style={{ position: "absolute" }}>
        <IconButton
          onClick={handleLogout}
          disabled={open && open?.pos_profile === selectedData?.cashier}
        >
          <ExitToAppIcon
            color={
              open && open?.pos_profile === selectedData?.cashier
                ? "disabled"
                : "error"
            }
          />
        </IconButton>
      </div>
      <Box className={classess.root}>
        <Box className={classess.paper}>
          <img src={activeImageTheme} alt={"logo"} className={classess.logo} />
          <Box mt={2} width={"100%"}>
            <FormControl
              variant="outlined"
              style={{ marginBottom: 20, width: "100%" }}
              disabled={open && open?.pos_profile === selectedData?.cashier}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Store
              </InputLabel>
              <Select
                startAdornment={
                  <InputAdornment position="start">
                    <span className="icon-shop" />
                  </InputAdornment>
                }
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedData ? selectedData.store : ""}
                IconComponent={ExpandMoreIcon}
                style={{ backgroundColor: "#FFF" }}
                onChange={handleChange}
                label="Store"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                input={
                  <OutlinedInput
                    label="Store"
                    name="Store"
                    id="demo-simple-select-outlined-label"
                  />
                }
              >
                {Array.isArray(stores) &&
                  stores.map((store, index) => (
                    <MenuItem
                      disabled={!hasPOSInWarehouse(store?.name)}
                      key={`stores-${index}`}
                      value={store?.name}
                    >
                      {store?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ marginBottom: 20, width: "100%" }}
              disabled={open && open?.pos_profile === selectedData?.cashier}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                POS
              </InputLabel>
              <Select
                startAdornment={
                  <InputAdornment position="start">
                    <span className="icon-pos" />
                  </InputAdornment>
                }
                style={{ backgroundColor: "#FFF" }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={
                  selectedData && selectedData?.cashier
                    ? selectedData.cashier
                    : ""
                }
                IconComponent={ExpandMoreIcon}
                onChange={handleCashierChange}
                label="POS"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                input={
                  <OutlinedInput
                    label="POS"
                    name="POS"
                    id="demo-simple-select-outlined-label"
                  />
                }
              >
                {cashiers.map((cashier, index) => (
                  <MenuItem
                    disabled={openedPos.includes(cashier.name) ? true : false}
                    key={index}
                    value={cashier.name}
                  >
                    {cashier.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
 
 
            {salesPersonList.length > 0 && (
        <FormControl variant="outlined" style={{ marginBottom: 20, width: "100%" }}>
          <InputLabel id="demo-simple-select-outlined-label">Sales Person</InputLabel>
          <Select
            startAdornment={
              <InputAdornment position="start">
                <span className="icon-pos" />
              </InputAdornment>
            }
            style={{ backgroundColor: "#FFF" }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedSalesPerson} // Bind to selected salesperson state
            onChange={handleSalesPersonChange}
            IconComponent={ExpandMoreIcon}
            label="Sales Person"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            input={
              <OutlinedInput
                label="Sales Person"
                name="Sales Person"
                id="demo-simple-select-outlined-label"
              />
            }
          >
            {salesPersonList.map((sales_person, index) => (
              <MenuItem key={index} value={sales_person.sales_person_name}>
                {sales_person.sales_person_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
          </Box>
          <Box width={"100%"}>
            <Button
              disabled={
                !selectedData || !selectedData.cashier || !selectedData.store
              }
              onClick={valid}
              className={classes.button}
              size={"large"}
              variant="contained"
              color="primary"
            >
              {t("common:valider")}
            </Button>
          </Box>
          <Dialog
            className={classes.paper}
            open={open1}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <Box className={classes.ticketBlock}>
                <Box className={classes.ticketHeader}>
                  <div className={classes.logo}>
                    <img
                      alt=""
                      src={"/img/log_100.png"}
                      style={{
                        display: "block",
                        marginLeft: "40%",
                        marginBottom: "30px",
                        width: "20%",
                      }}
                    />
                  </div>
                  <Box>
                    {globalDefaults?.default_company} :{" "}
                    {contactCompany && contactCompany.contact[0].warehouse_name}{" "}
                    <br /> {contactCompany && contactCompany.contact[0].city}
                  </Box>
                  <Box fontWeight={"normal"} fontSize={"12px"}>
                    {contactCompany && contactCompany.contact[0].address_line_1}
                  </Box>
                  <Box fontWeight={"normal"} fontSize={"12px"}>
                    {contactCompany && contactCompany.contact[0].mobile_no} |{" "}
                    {contactCompany && contactCompany.contact[0].phone_no}
                  </Box>
                  <Box fontWeight={"normal"} fontSize={"12px"}>
                    {formatDate(posOpenedDate, "LL")}{" "}
                    {formatDate(Date(), "HH:mm:ss")}
                  </Box>
                  <Box fontWeight={"normal"} fontSize={"12px"}>
                    {t("common:caisseVente")}: {open && open.pos_profile}
                  </Box>
                </Box>
                <Divider
                  style={{
                    background: "black",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                  variant="middle"
                />
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="textSecondary">
                      <strong>{t("common:ouvertureCaisse")}:</strong>
                    </Typography>
                    <br />
                    <Typography
                      style={{ marginBottom: 10 }}
                      variant="caption"
                      color="textSecondary"
                    >
                      <strong>{t("common:par")} :</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="textSecondary">
                      {open && open.period_start_date}
                    </Typography>
                    <br />
                    <Typography variant="caption" color="textSecondary">
                      {open && open.user}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    background: "black",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                  variant="middle"
                />
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="textSecondary">
                      <strong>{t("common:fondCaisse")} :</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="textSecondary">
                      {invoices && cushFund
                        ? (
                            invoices.reduce(
                              (a, v) => (a = a + parseFloat(v.grand_total)),
                              0
                            ) +
                            parseFloat(cushFund) -
                            coffreOperations.reduce(
                              (a, v) => (a = a + parseFloat(v.amount)),
                              0
                            )
                          ).toFixed(3)
                        : "0.000"}{" "}
                      {globalDefaults?.default_currency &&
                        globalDefaults?.default_currency}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  mt={5}
                  style={{
                    border: "1px solid gray",
                    padding: "30px 30px",
                    marginLeft: 100,
                    width: "50%",
                    height: "50%",
                  }}
                >
                  <Typography variant="caption">
                    {t("common:addSignValid")}
                  </Typography>
                  <SignaturePad
                    ref={signPad}
                    redrawOnResize
                    options={{ penColor: "#2F4B7C" }}
                  />
                  <Button color="primary" variant="contained" onClick={clear}>
                    {t("session:nettoyer")}
                  </Button>
                </Grid>
                <Link
                  component="button"
                  variant="body2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: 3,
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClickCapture={() => trim()}
                    onClick={handlePrint}
                  >
                    {t("login:visualiserRapport")}
                  </Button>
                </Link>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  ChangeThemeValid();
                  handleValidateWarehouse();
                }}
              >
                {t("common:valider")}
              </Button>
              <Button onClick={handleClose} color="primary">
                {t("common:fermer")}
              </Button>
            </DialogActions>
          </Dialog>
          <div className={classes.hidden}>
            <XReport
              details={invoices}
              isOpen={true}
              isSign={false}
              openSignature={openSignature}
              ref={componentRefX}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};